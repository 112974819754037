<template>
  <v-container>
    <h1 class="text-center">Добавить продажу для клиента</h1>
    <h2 class="grey--text text-center">
      {{ form_client.surname + ' ' + form_client.name + ' ' + form_client.second_name }}</h2>
    <form>
      <v-divider></v-divider>
      <div v-if="form_sale.length">
        <v-row v-for="(item,id) in form_sale" :key="item.barcode" no-gutters>
          <v-col cols="12" class="d-flex align-center mt-5">
            <strong>{{ id + 1 }}</strong>
            <pre>. </pre>
            {{ ' ' + item.name }}
          </v-col>
          <v-col cols="4" class="d-flex align-center">{{ item.price }} руб.</v-col>
          <v-col cols="4" class="d-flex align-center">
            <v-text-field v-model="item.pieces"  prefix="x "></v-text-field>
          </v-col>
          <v-col cols="4" class="d-flex align-center justify-end">
            <v-btn
                icon
                color="red"
                @click="deleteGood(id)"
                x-large
                outlined
            >
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <p class="text-center my-5">Список пока пуст...</p>
      </div>

      <v-row class="d-flex justify-space-between">
        <v-col cols="12" class="text-center">
          <v-btn
              @click="dialog = !dialog"
              color="green"
              outlined
              x-large
          ><v-icon>mdi-plus</v-icon></v-btn>
        </v-col>


      </v-row>

      <v-row class="text-center">
        <v-col cols="4" sm="3">
          <v-text-field v-model="form_client._discount" label="Скидка" outlined suffix="%"></v-text-field>
        </v-col>
        <v-col cols="8" sm="9">
          <strong>Общий итог: {{ total_cost }} руб.</strong>
        </v-col>
      </v-row>

      <v-row align-content="center">
        <v-col cols="12" class="d-flex justify-space-between">
          <v-btn
              @click="submitReset"
              class="mr-10"
              color="red"
              dark
              x-large
              outlined
          >Отменить
          </v-btn>

          <v-btn
              @click="submitSave"
              color="green"
              dark
              x-large
              outlined
          >
            Сохранить
          </v-btn>
        </v-col>

      </v-row>


    </form>
    <v-dialog
        v-model="dialog"
        max-width="500px"
    >
      <v-card class="py-5">
        <v-card-text>
          <v-autocomplete
              v-model="model"
              :items="goods"
              :search-input.sync="search"
              hide-no-data
              hide-selected
              item-text="name"
              item-value="barcode"
              label="Поиск позиции"
              placeholder="Введите первые символы..."
              prepend-icon="mdi-folder-search-outline"
              return-object
              class="mx-4"
          ></v-autocomplete>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              color="green"
              dark
              @click="addGood"
          >
            Добавить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import HTTP from "../api";

export default {
  data() {
    return {
      client: {
        name: '',
        second_name: '',
        surname: '',
        _discount: 0,
      },
      form_client: {},
      form_sale: [],
      dialog: false,
      goods: [],
      model: null,
      search: null,
      descriptionLimit: 20,
      entries: [],
      billDateCreated: null,
      bill_form: {},
    }
  },
  computed: {
    fields() {
      if (!this.model) return []

      return Object.keys(this.model).map(key => {
        return {
          key,
          value: this.model[key] || 'n/a',
        }
      })
    },
    items() {
      return this.entries.map(entry => {
        const Description = entry.Description.length > this.descriptionLimit
            ? entry.Description.slice(0, this.descriptionLimit) + '...'
            : entry.Description

        return Object.assign({}, entry, {Description})
      })
    },
    total_cost: function () {
      let result = this.form_sale.reduce(function (accumulator, currentValue) {
        return accumulator + (currentValue.price * currentValue.pieces);
      }, 0);
      return Math.floor(result * (100 - this.form_client._discount) / 100)
    }
  },
  methods: {
    deleteGood(index) {
      this.form_sale.splice(index, 1)
    },
    submitReset() {
      this.form_client = {...this.client}
      this.form_sale = []

    },
    submitSave() {
      this.bill_form = {
        "discount": +this.form_client._discount,
        "total_cost": this.total_cost,
        "client": this.client.id
      }
      HTTP.post('sales/bill/', this.bill_form)
          .then(response => {
            for (let i of this.form_sale) {
              const good_i = {
                "bill": response.data.id,
                "quantity": i.pieces,
                "good": i.id
              }
              HTTP.post('sales/bill_item/', good_i)
                  .then(() => {
                    console.log('CREATE ITEM BILL GOOD')
                  })
                  .catch((error => {
                    console.log('ERROR: ', error)
                  }))
            }
            this.$router.push({name: 'client-detail', params: {id: response.data.client}})
          })
          .catch((error => {
            console.log('ERROR: ', error)
          }))
    },

    addGood() {
      this.form_sale.push({
        id: this.model.barcode,
        name: this.model.name,
        price: this.model.price,
        pieces: 1,
      })
      this.dialog = false
      this.model = null
    }
  },
  watch: {
    search() {
      // Items have already been loaded
      if (this.items.length > 0) return

      // Items have already been requested
      if (this.isLoading) return

      this.isLoading = true
    },
  },
  mounted() {
    this.form = {...this.client}
    if (this.$route.name !== 'client-create') {
      HTTP
          .get(`clients/${this.$route.params.id}`)
          .then(response => (
              this.client = response.data,
              this.form_client = {...this.client}
          ));
      HTTP
          .get('goods/')
          .then(response => {
                this.goods = response.data.map(function (item) {
                  return {
                    name: item.name,
                    barcode: item.barcode,
                    price: +item.price,
                  }
                });
              }
          );
    } else {
      HTTP
          .get('goods/')
          .then(response => {
                this.goods = response.data;
              }
          );
    }

  },

}
</script>
